import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'

import Image from '../components/Image'

// import { Parallax, Background } from 'react-parallax';
import { Parallax } from '../components/Parallax/modules/index';

import Slider from "react-slick";
import { Link } from 'gatsby'

import './HomePage.scss'


const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 6000
};
export const HomePageTemplate = ({ title, subtitle, featuredImage, body }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      alt="Central Coast Transportation Company"
      subtitle={subtitle}
      backgroundImage={featuredImage}
      actionButtonText="Quick Quote"
      actionButtonLink="/quick-quote"
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
        {/* {todo: turn this into components} */}
        <div className="btn-more">
          <Link to="/quick-quote/" className="Button">
            Quick Quote
          </Link>
        </div>
        
        <h2>We offer transportation of:</h2>
        <ul className="points bg-white">
          <li>
            <Link to='/services' aria-label="Read more about our services">
              <div className="point--Image relative">
                <Image background src={"https://ucarecdn.com/37554083-89a9-4668-bffb-86acb7c6e49f/"} alt="warehouse" /> 
              </div>
              <div className="point--Content">
                <div className="point--Excerpt">Palletised goods such as food products, chemicals, building materials, motor vehicle parts, and bulk wholesale or retail goods.</div>
              </div>
            </Link>            
          </li>
          <li >
            <Link to='/freight-forwarding' aria-label="Read more about freight forwarding">
              <div className="point--Image relative">
                <Image background src={"https://ucarecdn.com/3cb44a06-7bdf-4113-9535-a59e88de9c42/"} alt="warehouse" /> 
              </div>
              <div className="point--Content">
                <div className="point--Excerpt">Customised solutions for your supply chain requirements including domestic and international sea and air freight forwarding services.</div>
              </div>
            </Link>
          </li>
          <li>
            <Link to='/delivery-areas' aria-label="Read more about our delivery areas">
              <div className="point--Image relative">
                <Image background src={"https://ucarecdn.com/a22e6bd1-99dc-4d6a-a528-65a8e0152db0/"} alt="warehouse" /> 
              </div>
              <div className="point--Content">
                <div className="point--Excerpt">Same day delivery services available between Sydney, Central Coast and Newcastle. Click to review our delivery areas and pickup times.</div>
              </div>
            </Link>
          </li>
        </ul>
        
      </div>
      

      <Parallax bgImage={'https://ucarecdn.com/f031b0c4-bce8-4fac-aad4-133bb68c9ba2/-/progressive/yes/-/format/auto/-/resize/2000x/'} strength={500}>
          <div className="container parallaxHeroHome">
              <div>
                <h1>Delivery areas</h1>
                <ul className="points bg-transparent">
                  <li>
                    <div className="point--Content">
                      <h3 className="point--Title">Central Coast</h3>
                      <div className="point--Excerpt">The team are Central Coast born and raised and know the area like the back of their hand. From Tuggerah to Terrigal, Wyong to Woy Woy and everywhere in between, we have your Central Coast transport needs covered!</div>
                    </div>
                  </li>
                  <li>
                    <div className="point--Content">
                      <h3 className="point--Title">Sydney</h3>
                      <div className="point--Excerpt">Whether you need to transport Industrial supplies from Wetherill Park, retail stock from Sydney CBD, or perhaps a lounge from your home in Cronulla, just call Pelican. We service every suburb of Sydney bar none!</div>
                    </div>
                  </li>
                  <li>
                    <div className="point--Content">
                      <h3 className="point--Title">Newcastle</h3>
                      <div className="point--Excerpt">So the Knights call Broadmeadow home, and the surfers love Mereweather, however you will find Pelican servicing Novocastrians city-wide. Need something transported to/from Newie? You need look no further!</div>
                    </div>
                  </li>
                </ul>
              </div>
          </div>
      </Parallax>

      <div className="container">
        <section className="section testimonials">
          <h1 id="testimonials">Testimonials</h1>
          <Slider {...settings}>
            <div>
            <blockquote>
              <p>Always reliable the team are friendly and they get the job done – no fuss no hassle – just great service. They are always willing to go above and beyond to get the job done. Highly recommend Pelican Carrying Company and Gosford Couriers, no matter the size of the job they always give it 100%. You can put your trust in the team at Pelican Carrying!</p>
              <p><strong>Tim Ball</strong></p>
              </blockquote>
            </div>
            <div>
              <blockquote>
                <p>Pelican are by far the easiest, friendliest, most reliable courier company I have had the pleasure working with. In addition, Leon is always so helpful and accommodating. Most of all, our customers really appreciate a pre-delivery. As a result, they know exactly when to expect their deliveries. Collecting and receiving our flooring orders is stress free and met with a happy hello.</p>
                <p><strong>Amber Johnson</strong></p>
                </blockquote>
            </div>
          </Slider>
        </section>
        <section className="section text-center">
          <div className="container">
            <h1>Free Quotes &amp; Independent Advice</h1>
            <p>Call us on <a href="tel:0243510142">(02) 4351 0142</a> or click below for a no-obligation quote</p>
            <Link to="/quick-quote/" className="Button">
            Quick Quote
            </Link>
          </div>
        </section>
      </div>
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
